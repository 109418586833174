import React, { Component } from "react";

export default class ExperienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="bf56719a-dd30-4b09-833f-7c41eeee086b"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="1094"
        height="760"
        viewBox="0 0 1094 760"
      >
        <title>career process</title>
        <rect
          x="155"
          y="304.52"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="291.81"
          y="344.05"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="428.63"
          y="383.57"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="155"
          y="245.24"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="291.81"
          y="284.76"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="428.63"
          y="324.29"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="580.64"
          y="304.52"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="717.45"
          y="344.05"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="854.27"
          y="383.57"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="580.64"
          y="245.24"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="717.45"
          y="284.76"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="854.27"
          y="324.29"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="155"
          y="166.19"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="291.81"
          y="205.71"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="428.63"
          y="245.24"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="155"
          y="106.9"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="291.81"
          y="146.43"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="428.63"
          y="185.95"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="580.64"
          y="166.19"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="717.45"
          y="205.71"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="854.27"
          y="245.24"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="580.64"
          y="106.9"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="717.45"
          y="146.43"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <rect
          x="854.27"
          y="185.95"
          width="130.73"
          height="39.52"
          rx="7.43"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <ellipse
          cx="547"
          cy="660.5"
          rx="547"
          ry="99.5"
          fill={theme.imageHighlight}
          opacity="0.1"
        />
        <polygon
          points="240 321 194 603 402 581 393 557 230.5 570.5 268.5 320.5 240 321"
          fill={theme.text}
        />
        <polygon points="186 1 466 0 547 613 266 651 186 1" fill={theme.text} />
        <polygon
          points="216.5 24.5 442.5 24.5 521.5 594.5 288.5 626.5 216.5 24.5"
          fill="#fff"
        />
        <path
          d="M482.64,638.86c1.42,2.72,2.48,5.66,4.33,8.11,5.4,7.22,15.65,8.35,23.34,13.06a2.15,2.15,0,0,1,.91.88c.35.83-.43,1.7-1.18,2.22-5.83,4.09-13.48,4.18-20.61,4.13a27.49,27.49,0,0,1-7.13-.68c-3.77-1-7-3.63-10.72-4.7a28.44,28.44,0,0,0-5.89-.83l-7.81-.53a18.41,18.41,0,0,1-6.09-1.13,6.16,6.16,0,0,1-3.84-4.55c-.22-1.66.49-3.27.91-4.89,1-3.84.69-8.48,3.68-11.1a11.35,11.35,0,0,1,4.15-2,132.39,132.39,0,0,1,16.61-4.42C477.93,631.52,480.59,634.92,482.64,638.86Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M394.3,660.49c.2,2.13.26,4.57-1.28,6.06a6.83,6.83,0,0,1-3.35,1.5c-7.39,1.72-15.63,1.84-22.06-2.18a6.09,6.09,0,0,1-1.74-1.49,5.43,5.43,0,0,1-.88-2.75c-.23-2.83.64-5.62,1.43-8.35a146.52,146.52,0,0,0,4.25-19.75c.08-.55,18.87,3,20.12,4.86,1.57,2.3,1.58,7.82,2,10.56Q393.74,654.69,394.3,660.49Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M464.24,400.27l5,46.49c1,9.5,2.05,19.05,1.36,28.58-.8,11-3.88,22-2.58,32.9.51,4.24,1.67,8.4,1.84,12.67a69,69,0,0,1-.43,8.5c-2.26,28.21,4.89,56.2,8.89,84.21a36.54,36.54,0,0,1,.42,10.48c-.52,3.55-1.56,8.21,1.6,9.89l-29.68,10.76c-3.79-4.75-1.28-12.14-2-18.17-.66-5.43-3.64-10.27-5.23-15.5-1.44-4.7-1.74-9.65-2.21-14.53-2.59-26.57-10.54-52.45-12.4-79.08a36,36,0,0,0-1-7.63c-1-3.31-3-6.2-4.46-9.31-2.24-4.67-3.34-9.79-4.42-14.85l-6.35-29.75c-6.42,9.56-6.16,22-7.43,33.46-1.78,16-5.76,31.73-9,47.53a94.47,94.47,0,0,0-1.69,10.3c-.84,9.27.83,18.63-.06,27.89-1,10.73-5.51,21.2-4.42,31.93.6,5.94,2.88,11.56,4.42,17.32s2.3,12.08-.05,17.57a54,54,0,0,0-25.71-2.84c-9.53-9.75-11.22-23.35-11.87-37s.92-27.32-.78-40.84c-.5-4-1.28-7.9-1.41-11.89-.42-12.79,5.73-24.76,8.79-37.19a78.7,78.7,0,0,0,.77-34.15c-1.5-7.47-4.08-14.72-5-22.29-1.25-10.61.87-21.3,3-31.78,1.43-7.07,2.85-14.14,4.47-21.17,1.74-7.55,3.81-15.26,8.46-21.44,7.44-9.89,20.65-14.28,33-13.32s23.88,6.58,34.08,13.58A128.48,128.48,0,0,1,464.24,400.27Z"
          transform="translate(-53 -70)"
          fill={theme.dark}
        />
        <path
          d="M431.06,222.25a8.63,8.63,0,0,0-1.15,2.94c-.25,2.05,1,3.93,2,5.72a31.15,31.15,0,0,1,2.93,7.3,12,12,0,0,1,.43,4.87,11.75,11.75,0,0,1-1.3,3.5c-1.93,3.72-4.79,7.16-8.68,8.71a15.29,15.29,0,0,1-9.39.38,28.51,28.51,0,0,1-8.59-4.08,26.11,26.11,0,0,1-5.88-5.1,35.25,35.25,0,0,1-4.06-7c-2.08-4.35-4.19-9-3.81-13.77a4.6,4.6,0,0,1,.24-1.27,6.83,6.83,0,0,1,1.34-2,26.1,26.1,0,0,0,6.12-18.15l14.57,3.31c3.21.73,6.42,1.46,9.59,2.37,2.6.75,6.6,1.79,8.29,4.11S432.21,220.13,431.06,222.25Z"
          transform="translate(-53 -70)"
          fill="#fbbebe"
        />
        <circle cx="370.26" cy="123.29" r="26.48" fill="#fbbebe" />
        <path
          d="M419,246.71c1.12-2.45,3.54-4,5.75-5.54s4.45-3.51,4.88-6.18a4.43,4.43,0,0,1,.44-1.74c.85-1.28,2.87-.64,4.08.3,2.94,2.29,4.94,5.54,6.89,8.71a74.43,74.43,0,0,1,6.45,12.19c2.81,7.41,3.15,15.5,3.46,23.41.28,7.2.55,14.51-1.2,21.49-1.1,4.42-3,8.71-3.11,13.26-.33,16.53-6.07,32.33-6.47,48.86a126.75,126.75,0,0,0,.72,14.31l.94,10.73q-21.81-1.25-43.5-3.83c-.42-4.94-1.83-9.73-3.12-14.51a314.93,314.93,0,0,1-10.11-59.74c-.77-10.72-1.56-21.4-2.75-32.08-1.07-9.71-3.17-19.48-1.91-29.17a40.27,40.27,0,0,1,4.1-12.62,8.32,8.32,0,0,1,3.2-3.83A8.65,8.65,0,0,1,390,230a41.94,41.94,0,0,1,6.88-.81,4.74,4.74,0,0,1,1.68.16,5.27,5.27,0,0,1,2.29,2.22A41.52,41.52,0,0,0,419,246.71Z"
          transform="translate(-53 -70)"
          fill="#f2f2f2"
        />
        <path
          d="M406.72,244.59c1.86,2.66,5.8,3.29,7.52,6a15.73,15.73,0,0,1,1.31,3.57c1.05,3.35,3.17,6.26,4.46,9.53,1.86,4.7,1.91,9.9,1.93,14.95a49.47,49.47,0,0,1-.92,12c-.6,2.47-1.58,4.82-2.3,7.26a35.5,35.5,0,0,0-1.34,12.8,5.24,5.24,0,0,0,.72,2.65,5.75,5.75,0,0,0,2.56,1.74c2.93,1.29,6,2.61,9.2,2.31a3.37,3.37,0,0,0,1.83-.61,4.07,4.07,0,0,0,.9-1.19l2-3.38c1.81-3.09,3.7-6.55,3-10.08a20,20,0,0,0-2.27-5.17,60.78,60.78,0,0,1-3.26-8.07c-3.19-9.17-6.42-18.47-7.14-28.15a24.74,24.74,0,0,1,1.2-10.92,31.1,31.1,0,0,1,3.52-6.09,2.61,2.61,0,0,0-2.81-.95,16.72,16.72,0,0,0-2.91,1.21c-2.94,1.24-6.2.79-9.17-.06-1.16-.34-3-1.5-4.2-1.42C410.14,242.57,406.79,244.71,406.72,244.59Z"
          transform="translate(-53 -70)"
          fill={theme.imageHighlight}
        />
        <path
          d="M430.13,244.48c-.6-.48-1.53-.92-2.06-.36a2.32,2.32,0,0,0-.38.81c-.52,1.4-2.05,2.09-3.44,2.63s-2.89.84-3.73-.19a2.26,2.26,0,0,1,.13-2.67,8.16,8.16,0,0,1,2.19-1.85,21.38,21.38,0,0,0,8.72-13.72,6.52,6.52,0,0,1,2.39,4.13c.33,1.59.37,3.23.7,4.82.49,2.28,1.58,4.41,1.83,6.72.13,1.23,0,2.47,0,3.7a22.71,22.71,0,0,0,.42,3.58C434.56,249.6,432.91,246.69,430.13,244.48Z"
          transform="translate(-53 -70)"
          fill="#f2f2f2"
        />
        <path
          d="M409.89,237.69c-1-.95-2-1.85-3-2.82a38.9,38.9,0,0,1-9.67-15.35c-1.28,1-2.69,2.32-2.72,4a6.13,6.13,0,0,0,1.32,3.25,50.68,50.68,0,0,1,7.33,19.61,5.84,5.84,0,0,1,5.05-2.69c1.58,0,8.67,4.2,9.18,3.14C418.13,245.17,411.09,238.82,409.89,237.69Z"
          transform="translate(-53 -70)"
          fill="#f2f2f2"
        />
        <path
          d="M432.22,229.73a83,83,0,0,0,33.58,20.33,7.54,7.54,0,0,1,3.39,1.72,6.93,6.93,0,0,1,1.41,3.1c2.44,9.54,3.26,19.41,4.07,29.22a16.28,16.28,0,0,1-.1,4.88c-.59,2.62-2.45,4.74-4.28,6.71-5.74,6.15-12.29,12.58-13.26,20.94a33.45,33.45,0,0,1-.57,4.8c-.5,1.86-1.61,3.5-2.29,5.31-3.34,8.91,4.21,19.7-.39,28A50.54,50.54,0,0,1,458.84,370a19.86,19.86,0,0,0,1.37,5.88c1.37,2.86,4.19,4.68,6.41,7,6.77,6.94,7.6,17.56,8,27.25.25,5.46.17,11.73-4,15.31-2.41,2.09-5.7,2.77-8.85,3.29-4.19.7-8.75,1.2-12.46-.87-2.66-1.48-4.47-4.07-6.2-6.57-3.28-4.77-6.57-9.53-9.68-14.42-4.92-7.71-9.56-16.52-8.14-25.56-.4,9.25-2.35,18.57-6.89,26.63s-11.85,14.79-20.72,17.45-19.24.78-25.85-5.7a34.94,34.94,0,0,1-5.51-7.43,72.52,72.52,0,0,1-8.14-20.71c-.43-1.89-.75-4,.2-5.69.45-.8,1.14-1.43,1.61-2.22,1.58-2.69.06-6.08-1.47-8.82-2.33.22-4-2.52-3.79-4.86s1.61-4.37,2.6-6.5c2.9-6.28,2.26-13.59.86-20.36s-3.49-13.5-3.33-20.41c.17-7.31-5.09-13.89-9.1-20-2.24-3.44-4.85-6.64-6.8-10.25a33.07,33.07,0,0,1-2.62-24.76,64.89,64.89,0,0,1,5.48-12.22l3.3-6.19a10.21,10.21,0,0,1,7-6l22.83-9a34.35,34.35,0,0,0,8.24-4.12c2.79-2.1,4.87-5,7.53-7.24a4.22,4.22,0,0,1,3-1.31c2.14.19,2.9,2.89,3.12,5,.5,4.72,4,8.72,5.19,13.31,6.44,25.35,20.07,48.73,23.81,74.61,4.2-10.09,8.4-20.2,13.78-29.7a24.66,24.66,0,0,0,2.13-4.31,18.4,18.4,0,0,0,.71-6.69c-.27-6.51-2-12.87-3.71-19.16C436.53,246.1,434.53,238.21,432.22,229.73Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M475.73,307.19c.44,4.26.87,8.53,1.66,12.74.92,4.87,2.32,9.63,3.71,14.39a37.45,37.45,0,0,0,2.9,7.73,9.94,9.94,0,0,1,1.07,2.29c.49,2-.63,4-1.11,6-1.44,6,2.88,12.67.16,18.2-.82,1.68-2.25,3.07-2.79,4.87-.63,2.07,0,4.28.33,6.42.85,6-1.2,12.1-3.22,17.87L475,407.44c-3.14-5.84-6.31-11.74-10.65-16.75-1.55-1.8-3.24-3.47-4.67-5.37a37.66,37.66,0,0,1-5-9.74,84.43,84.43,0,0,1-5.17-25.72,17.59,17.59,0,0,1,.85-7.4,39.36,39.36,0,0,1,2.22-4c2.68-4.83,3.23-10.55,3.18-16.07s-.64-11.06,0-16.55a37.86,37.86,0,0,1,4-13.13,18.63,18.63,0,0,1,5.43-6.77,9.78,9.78,0,0,1,6-1.95c3.27.19,2.52,3,2.8,5.71Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M328.64,293.58q-3.06,9-5.52,18.16-2.4,9-4.29,18.1a38.76,38.76,0,0,0-1.08,8.14,36.42,36.42,0,0,0,1.63,9.41c6.44,23.21,18.18,44.62,31.4,64.75A58.28,58.28,0,0,1,364.08,400a2.85,2.85,0,0,0,1-1,2.71,2.71,0,0,0-.32-2.34c-3.65-7.48-9.43-14.07-11.25-22.2-.76-3.38-.79-6.9-1.65-10.26-1.11-4.35-3.59-8.33-4.16-12.79-.61-4.86,1-10.16-1.28-14.47,4.65-1.81,7.34-6.6,9.31-11.19a61.29,61.29,0,0,0,4.48-14.71,44.26,44.26,0,0,0-5.41-28.15,45,45,0,0,0-9.05-11.36c-1.19-1.07-4-4.14-5.73-4.11-2.31.05-3.05,3.79-3.71,5.47C333.64,279.75,331,286.62,328.64,293.58Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M370.06,405.51c.3.22.64.52.56.88s-.37.45-.63.6a7.76,7.76,0,0,0-2.88,3.9,108.85,108.85,0,0,0-4.34,11.49,50.37,50.37,0,0,0-7.84-9.15c-2.28-2.1-4.83-4.09-6.05-6.93,0-.08,5.24-3.95,5.72-4.37,1.47-1.31,3.32-4.94,4.84-5.74,1.72-.9,3.36,2.63,4.67,4.05A39.72,39.72,0,0,0,370.06,405.51Z"
          transform="translate(-53 -70)"
          fill="#fbbebe"
        />
        <path
          d="M451.38,189.15c-.07-1.31-.24-2.76-1.24-3.61a4.73,4.73,0,0,0-3-.83c-5.83-.19-11.86.79-17.36-1.17-3.62-1.29-6.71-3.77-10.24-5.31s-8.05-1.95-10.9.64c-2.16,2-2.75,5.07-3.5,7.9a46.32,46.32,0,0,1-8.63,17.12c-2.71-.84-4.65-3.26-5.9-5.8-4.25-8.66-2.16-19,.86-28.18,1-3.08,2.11-6.13,3.22-9.17l2.44-6.72a13.12,13.12,0,0,0,8.36-4.16l1.05,2.55a13.3,13.3,0,0,0,7.5-2.65c.84,1.58,3,2,4.75,1.49s3.26-1.49,5-2c4.65-1.5,9.61.81,14.49,1.07a58,58,0,0,1,6.19.18c5.76.93,9.83,6.35,11.59,11.91,1.17,3.7,2.14,8.25,5.79,9.56.08,1.4-2.4,1.67-2.61,3a2.3,2.3,0,0,0,.3,1.27l3.35,7.19a8.32,8.32,0,0,1-4.84-.24C456.54,186.74,451.53,191.86,451.38,189.15Z"
          transform="translate(-53 -70)"
          fill={theme.dark}
        />
        <path
          d="M621.14,204.8a131.36,131.36,0,0,1,4,16.73c.72,4.24,1.14,8.95-1.29,12.49,9.26-13.93,18.93-28.28,33-37.27A30.13,30.13,0,0,1,649,173.51c.08-.84-11.55,3.55-12.44,4.05-4.08,2.27-7.82,5.09-11.8,7.52-2.06,1.25-10.64,4.21-10.72,6,0,1.15,3.41,4.67,4.05,5.88A45.67,45.67,0,0,1,621.14,204.8Z"
          transform="translate(-53 -70)"
          fill="#fbbebe"
        />
        <path
          d="M664.17,730.18a15.5,15.5,0,0,0-15.84-1.2c-4.53,2.34-7.58,6.77-11.7,9.77-7.08,5.14-16.43,5.55-24.72,8.33-2.2.74-4.54,1.84-5.47,4a7.1,7.1,0,0,0,.41,5.57,14.42,14.42,0,0,0,6.86,7.07c3.72,1.78,8,1.86,12.12,1.91l35.8.42c11,.13,22.07.26,33,1.63,2.55.32,5.31.67,7.56-.58,3.66-2,4.08-7.06,3.8-11.23a103.07,103.07,0,0,0-7-30.8c-.43-1.09-1-2.33-2.21-2.53a3.6,3.6,0,0,0-2.38.76c-10.1,6-22.89,9.39-33.63,4.59"
          transform="translate(-53 -70)"
          fill="#fff"
        />
        <path
          d="M682.09,501.78c-4,23.23-1.39,47.1,3.11,70.23a122.46,122.46,0,0,0,3.27,13.64c1.61,5.1,3.78,10,5.29,15.13,3.84,13,3.3,26.9,2.73,40.47L693.87,703c-.22,5.18-.42,10.48,1.14,15.42.63,2,1.4,4.5-.19,5.85a4.46,4.46,0,0,1-2.11.81,32.66,32.66,0,0,1-21-3.15L671,659.55c-.22-19.23-.45-38.54-3.41-57.53-2.35-15.1-6.43-30-6.7-45.28-.28-16,3.64-31.79,7.64-47.3.86-3.32,4-5.75,6.58-8a8.09,8.09,0,0,1,3.2-1.94C679.51,499.17,681.35,500.77,682.09,501.78Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M682.09,501.78c-4,23.23-1.39,47.1,3.11,70.23a122.46,122.46,0,0,0,3.27,13.64c1.61,5.1,3.78,10,5.29,15.13,3.84,13,3.3,26.9,2.73,40.47L693.87,703c-.22,5.18-.42,10.48,1.14,15.42.63,2,1.4,4.5-.19,5.85a4.46,4.46,0,0,1-2.11.81,32.66,32.66,0,0,1-21-3.15L671,659.55c-.22-19.23-.45-38.54-3.41-57.53-2.35-15.1-6.43-30-6.7-45.28-.28-16,3.64-31.79,7.64-47.3.86-3.32,4-5.75,6.58-8a8.09,8.09,0,0,1,3.2-1.94C679.51,499.17,681.35,500.77,682.09,501.78Z"
          transform="translate(-53 -70)"
          opacity="0.1"
        />
        <path
          d="M607,429.18c-1.48,6.09,1.66,12.4,1.31,18.66-.2,3.79-1.69,7.57-1,11.29s3.35,6.52,4.77,9.93a26.94,26.94,0,0,1,1.62,8.88c.41,7.48.08,15-.2,22.45-.47,12.5-.82,25.07.83,37.46A117.19,117.19,0,0,0,623,569a54,54,0,0,1,3.23,8.39,59.88,59.88,0,0,1,1.1,8.14,82.7,82.7,0,0,0,7.07,25.71c2.55,5.57,7,10,8.41,15.91,1.34,5.66.44,11.63,1.41,17.36.71,4.25,2.44,8.27,3.4,12.47,1.25,5.43,1.22,11.06,1.85,16.6,1.8,16,9.07,32,4.73,47.52-.87,3.12-2.22,6.29-1.61,9.47.3,1.56,1.06,3.1.72,4.65-.28,1.32-1.3,2.34-1.82,3.59-1,2.34.06,5.12,1.84,6.93a19.69,19.69,0,0,0,6.5,3.84c7.37,3.15,15,6.35,23,6.28a6.18,6.18,0,0,0,2.83-.52c1.44-.75,2.15-2.38,2.76-3.88l4.1-10.14c.5-1.23,1-2.67.31-3.81a7.1,7.1,0,0,0-1.72-1.6c-3.31-2.79-2.56-8-1.68-12.19a177.87,177.87,0,0,0,3.72-32.7c.44-21.92-3.17-43.71-6.77-65.34-1.25-7.51-2.51-15.07-5-22.27s-6.16-14.13-5.56-21.61c.64-8.08-1.46-16.55,0-24.52s3.77-15.77,5.26-23.73c1.93-10.33,2.41-20.86,3-31.34.25-4.28.57-8.7,2.57-12.5,1.17-2.22,2.87-4.12,4.18-6.27,3.38-5.59,3.92-12.52,7.15-18.2,1.53-2.69,3.64-5.06,4.76-7.94a22.19,22.19,0,0,0,1.22-7.06,84,84,0,0,0-1-18.05,117.45,117.45,0,0,0-55-12.76c-7.31.12-14.7.86-21.56,3.39C619.86,425.25,613.9,428.66,607,429.18Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M648,746c-2.94,3.66-2.74,8.89-4.62,13.2-2.28,5.23-7.36,8.58-12.18,11.63l-30,19c-1.64,1-3.4,2.23-4,4.09a6.56,6.56,0,0,0,.15,3.59,15.58,15.58,0,0,0,10.48,10.64,31,31,0,0,0,8.11.83l13.49.18a79,79,0,0,0,12.31-.48c3.74-.54,7.38-1.61,11.1-2.29,6.77-1.23,13.73-1.12,20.51-2.31a108.46,108.46,0,0,0,12.28-3.17c4.9-1.47,9.89-3,14.09-5.91a7.31,7.31,0,0,0,2.71-2.92c.71-1.65.32-3.54-.08-5.29l-6.44-28.3a16.87,16.87,0,0,0-2.52-6.61c-1.43-1.9-4-3.16-6.23-2.35a13.21,13.21,0,0,0-2.59,1.56c-3.3,2.17-7.53,2.53-11.43,1.85-2.52-.45-5.85-1.14-7.88-2.81-1.54-1.27-2-3.24-3.31-4.68A9.39,9.39,0,0,0,648,746Z"
          transform="translate(-53 -70)"
          fill="#fff"
        />
        <circle cx="575.55" cy="94.77" r="36.13" fill="#fbbebe" />
        <path
          d="M600.68,166.89c3.46-1.94,3.46-7.53,7-9.39s7.56,1.37,9.83,4.63A42.59,42.59,0,0,1,624.26,178c.32,1.62.62,3.39,1.84,4.52s2.82,1.27,4.38,1.43q6.85.68,13.72,1.24c5.1.41,10.48.74,15.11-1.45a11,11,0,0,0,4.54-3.76,16.92,16.92,0,0,0,1.94-5.08c2.39-9.09,4.8-18.29,5-27.69.08-3.88-.26-7.9-2.13-11.3-1.09-2-2.69-3.74-3.14-5.94s1-5,3.28-4.76a13.46,13.46,0,0,1-6-1.14c-1.81-1-3.15-3.15-2.51-5.11-5.08.65-8.78-4.37-12.74-7.62a3.45,3.45,0,0,0-2.42-1,8.73,8.73,0,0,0-2,.77c-2,.7-4.09-.63-6.14-1.21-5.5-1.54-12.49,2.18-16.57-1.82.39,2.55-2.74,4.26-5.32,4.21s-5.24-1-7.68-.14a5,5,0,0,1-2.86,7.16l1.21,2.64a6.82,6.82,0,0,0-5.25,4.92c-1.33,5.25-1,12.71-.68,18.11C600,147.35,602.31,166,600.68,166.89Z"
          transform="translate(-53 -70)"
          fill={theme.dark}
        />
        <path
          d="M631.51,211.34c-18.15,20.41-22.77,49.14-26.64,76.19l-5.7,39.94c-1.48,10.36-3,20.72-4.29,31.11-.9,7.11-1.74,14.33-.76,21.43.77,5.6,2.64,11,4.2,16.39a154.56,154.56,0,0,1,6,43.49c1.21-3.56,5.4-5,9.08-5.8a146.82,146.82,0,0,1,91.86,9.81c5.05-14.15,4.1-29.63,5.51-44.59,2-21.33,8.89-42,10.93-63.29.75-7.93.84-15.9.92-23.87.06-5.85.12-11.76-1.1-17.49-1.36-6.34-4.23-12.25-7.2-18A469.1,469.1,0,0,0,687,230.24q-7.54-11.28-15.73-22.11-4.15-5.49-8.47-10.86c-2.79-3.47-4-6.48-8.43-4.4C646,196.79,637.6,204.49,631.51,211.34Z"
          transform="translate(-53 -70)"
          fill={theme.imageHighlight}
        />
        <path
          d="M641.91,381.22a98,98,0,0,1-13.27-.42c-3.27-.32-6.61-.8-9.8,0a10.2,10.2,0,0,0-3.81,1.87,2.87,2.87,0,0,0,1.63,5l3.42.3c2.17.19,4.43.41,6.12,2a3.82,3.82,0,0,1,1.24,2.41c.21,2.33-1.81,3.6-3.62,4.57a28.69,28.69,0,0,0-7.06,4.7,9.78,9.78,0,0,0-2.93,6,1.16,1.16,0,0,0,1.7,1.07c4-2,6.61-7.93,11-6.94a17.82,17.82,0,0,0-9.66,7.43,4.48,4.48,0,0,0,6,6.33,19.78,19.78,0,0,0,1.88-1.25,46.21,46.21,0,0,0,7.93-8.24c-3.2,6.75-6.44,14.65-2.76,21.36a2.13,2.13,0,0,0,2,1.18c1.16-.12,1.5-1.21,1.69-2.17.6-3.09.75-6.35,2.27-9.1,3.52-6.38,12.2-7,18.81-10.11,7.93-3.68,13.36-11.6,15.55-20.06a15.42,15.42,0,0,0,.39-7.62c-1.47-5.69-8.49-7-13-4.25C652.1,378.79,648.89,381,641.91,381.22Z"
          transform="translate(-53 -70)"
          fill="#fbbebe"
        />
        <path
          d="M634.89,269.29c7,10.81,19.15,16.9,29.57,24.49A24.79,24.79,0,0,1,671,300c2.63,4.06,3,9.22,2.46,14-.68,6.31-2.71,12.38-4.72,18.4l-4.37,13.05c-2.77,8.28-5.63,16.73-10.9,23.69-1.35,1.77-2.92,3.66-2.86,5.88a7.6,7.6,0,0,0,1.28,3.58,24.08,24.08,0,0,0,15.29,10.92,11.66,11.66,0,0,0,5.75,0c2.7-.8,4.73-3,6.61-5.08,9-9.93,18-20,24.5-31.65,6.35-11.37,10.15-24,13.65-36.51a76.86,76.86,0,0,0,3-14.13c.74-9-1.62-18-5.48-26.13s-9.17-15.49-14.64-22.64a157.58,157.58,0,0,0-17.15-19.68c-10.88-10.17-24.19-16.15-38.75-10.38-10.28,4.08-14.13,12.59-15.05,23.49A35.74,35.74,0,0,0,634.89,269.29Z"
          transform="translate(-53 -70)"
          opacity="0.1"
        />
        <path
          d="M638.53,266.55c7,10.81,19.16,16.9,29.57,24.49a24.87,24.87,0,0,1,6.51,6.26c2.63,4.06,3,9.21,2.46,14-.69,6.31-2.71,12.38-4.73,18.4l-4.37,13c-2.77,8.28-5.63,16.72-10.9,23.69-1.34,1.77-2.91,3.65-2.85,5.88a7.42,7.42,0,0,0,1.28,3.57,24.07,24.07,0,0,0,15.28,10.93,11.7,11.7,0,0,0,5.76,0c2.7-.79,4.72-3,6.61-5.07,9-9.94,18-20,24.5-31.65,6.35-11.37,10.15-24,13.65-36.52a76.08,76.08,0,0,0,3-14.12c.74-9-1.62-18-5.47-26.14s-9.17-15.49-14.64-22.64A157.65,157.65,0,0,0,687,231.06c-10.87-10.16-24.18-16.15-38.74-10.37-10.29,4.08-14.13,12.58-15.06,23.49A35.77,35.77,0,0,0,638.53,266.55Z"
          transform="translate(-53 -70)"
          fill={theme.imageHighlight}
        />
        <path
          d="M859.65,625.24a37,37,0,0,1-.8,7.76c-.1.48-.21,1-.32,1.41-2.84,11.39-10.85,19.72-20.41,20.25-.32,0-.64,0-1,0-10.11,0-18.66-8.72-21.48-20.73-.08-.32-.15-.64-.22-1a37,37,0,0,1-.8-7.76c0-16.27,10.07-29.45,22.5-29.45S859.65,609,859.65,625.24Z"
          transform="translate(-53 -70)"
          fill={theme.text}
        />
        <path
          d="M859.65,625.24a37,37,0,0,1-.8,7.76c-.1.48-.21,1-.32,1.41-.34,0-.67,0-1,0a45.76,45.76,0,0,1-7.36-1,44.92,44.92,0,0,1-6.56,1.5,45.87,45.87,0,0,1-5.14.48l-1.74,0a46.41,46.41,0,0,1-6.16-.41,45.17,45.17,0,0,1-9.67-2.4,45.56,45.56,0,0,1-5.22,1.4c-.08-.32-.15-.64-.22-1a37,37,0,0,1-.8-7.76c0-16.27,10.07-29.45,22.5-29.45S859.65,609,859.65,625.24Z"
          transform="translate(-53 -70)"
          opacity="0.1"
        />
        <path
          d="M907.65,570.38a45.54,45.54,0,0,0-4.9-20.61L876.29,558l23.23-13.66a45.73,45.73,0,0,0-34.36-19.58,45.65,45.65,0,0,0-3.57-4.72l-38,11.83,31.17-18.33a45.73,45.73,0,0,0-72,24.39l32.55,37.47L780,549.86a45.74,45.74,0,0,0,40.93,80.7,45.92,45.92,0,0,0,29.28.81,45.78,45.78,0,0,0,55.62-44.66c0-1,0-2-.1-3A45.63,45.63,0,0,0,907.65,570.38Z"
          transform="translate(-53 -70)"
          fill={theme.imageHighlight}
        />
        <path
          d="M906.86,578.86a121.9,121.9,0,0,0-42.34-.54c-15.89,2.63-32.13,8.41-47.67,4.19-9.12-2.48-17-8.22-25.91-11.41a49.18,49.18,0,0,0-26.75-1.6,45.76,45.76,0,0,0,56.69,61.06,45.92,45.92,0,0,0,29.28.81,45.78,45.78,0,0,0,55.62-44.66c0-1,0-2-.1-3A45.19,45.19,0,0,0,906.86,578.86Z"
          transform="translate(-53 -70)"
          opacity="0.1"
        />
      </svg>
    );
  }
}
